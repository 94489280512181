import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AnimatedCursor from 'react-animated-cursor';

const Projects = () => {
  return (
    <section id="projects">
        <AnimatedCursor 
          innerSize={10}
          outerSize={10}
          color='153, 186, 130'
          outerAlpha={0.6}
          innerScale={0.7}
          outerScale={3}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
        <h2 className='title'>PROJECTS</h2>
        <Container>
            <Row className='projects'>
                <a href="https://amrit1-1.github.io/Sale-Snatchers/" target="_blank">
                    <h4 className='project-title'>Sale Snatchers</h4>
                </a>
                <p>Sale Snatchers is a fun game I created using HTML, CSS and Javascript with no external libraries. The user has to move the trolley to catch the falling items, collecting as many 'savings' as they can in 60s. There are also some game-changing special items that the user should grab, and some they should avoid.</p>
                <div>
                    <a className='project-link' href="https://github.com/amrit1-1/Sale-Snatchers" target="_blank">GitHub</a>
                </div>
            </Row>
            <Row className='projects'>
                <a href="https://rare-labs-beauty.onrender.com" target="_blank">
                    <h4 className='project-title'>Rare Labs Beauty</h4>
                </a>
                <p>This project is a web application that simulates a cosmetics line with products, a shopping basket, register and login page. The 'login' element stores one users data after registering. This is a demonstration of my front-end development skills.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/RareLabsBeauty" target="_blank">GitHub</a>
                    </div>
            </Row>
            <Row className='projects'>
                <a href="https://car-inventory-xow6.onrender.com" target="_blank">
                    <h4 className='project-title'>Car Inventory</h4>
                </a>
                <p>This application retrieves and sends data to a MongoDB database and allows a user to add, edit, and delete car entries. This is a demonstration of my full-stack development skills.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/Car-Inventory" target="_blank">GitHub</a>
                    </div>
            </Row>
            <Row className='projects'>
                <a href="https://itunes-search-frontend.onrender.com" target="_blank">
                    <h4 className='project-title'>iTunes Search</h4>
                </a>
                <p>A web application with JWT authentication that utilises the iTunes Search API so a user can search and 'favourite' different media content.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/iTunes-Search" target="_blank">GitHub</a>
                    </div>
            </Row>
            <Row className='projects'>
                <a href="https://to-do-list-with-sign-up.onrender.com" target="_blank">
                    <h4 className='project-title'>To-Do List with Login</h4>
                </a>
                <p>A task managing web application that utilises a sign-up form with JWT authentication and redux for state management. Full functionality in managing tasks is available if the username ends in '@gmail.com'.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/To-Do-List-with-Sign-Up" target="_blank">GitHub</a>
                    </div>
            </Row>
            <Row className='projects'>
                <a href="https://skims-clone.onrender.com" target="_blank">
                    <h4 className='project-title'>Skims Clone</h4>
                </a>
                <p>This React.js application is a clone of the Skims website which I created to push my CSS and Bootstrap styling skills.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/Skims-Clone" target="_blank">GitHub</a>
                    </div>
            </Row>
            <Row className='projects'>
                <a href="https://to-do-list-t0et.onrender.com" target="_blank">
                    <h4 className='project-title'>To-Do List with Redux</h4>
                </a>
                <p>For one of my first React.js applications, I built a simple task manager with add, edit, delete, and 'complete' functionalities.</p>
                    <div>
                        <a className='project-link' href="https://github.com/amrit1-1/To-Do-App-with-Redux" target="_blank">GitHub</a>
                    </div>
            </Row>
        </Container>
    </section>
  );
};

export default Projects;
